:root {
  --full-width-mode-max-width: 723px;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: 'Red Hat Display', -apple-system, 'Noto Sans', 'Helvetica Neue', Helvetica,
    'Nimbus Sans L', Arial, 'Liberation Sans', 'PingFang SC', 'Hiragino Sans GB',
    'Noto Sans CJK SC', 'Source Han Sans SC', 'Source Han Sans CN',
    'Microsoft YaHei', 'Wenquanyi Micro Hei', 'WenQuanYi Zen Hei', 'ST Heiti',
    SimHei, 'WenQuanYi Zen Hei Sharp', sans-serif;
}

html,
body {
  margin: 0;
  padding: 0;
  font-size: 18px;
  color: #1C1C1C;
  background-color: #F4F4F4;
  -webkit-font-smoothing: antialiased;
}

/* disable focus blue highlight */
input:focus, textarea:focus, button:focus {
  outline: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

@media only screen and (min-width: 600px) {
  html,
  body {
    font-size: 20px;
  }
}

/* disable chrome click feedback box */
* { 
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); 
}


/* recaptcha */
.grecaptcha-badge { 
  visibility: hidden;
}

/* react modal */
.ReactModal__Overlay {
  opacity: 0;
  -webkit-transition: opacity 0.2s ease-in-out;
  -moz-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out;
}

.ReactModal__Overlay-show {
  opacity: 1;
}

.ReactModal__Overlay--on-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.sidebar {
  margin-left: -17rem;
}

.sidebar--on-open {
  margin-left: 0;
}

.message-modal {
  transform: scale(0.8);
}

.message-modal--on-open {
  transform: scale(1);
}

.ReactModal__Overlay--before-close .message-modal {
  transform: scale(0.8);
}

/* recaptcha */
.grecaptcha-badge { 
  visibility: hidden;
}