/*
  Customized styling
  Reference - https://react-day-picker.js.org/docs/styling/
*/

.DayPicker {
  display: inline-block;
  font-size: 0.778rem;
  background: #333333;
  color: white;
  border-radius: 10px;
}

.DayPicker-wrapper {
  position: relative;

  flex-direction: row;
  padding-bottom: 1em;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.DayPicker-Months {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.DayPicker-Month {
  display: table;
  margin: 0 1em;
  margin-top: 1em;
  border-spacing: 0;
  border-collapse: collapse;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.DayPicker-NavBar {
}

.DayPicker-NavButton {
  position: absolute;
  top: 1em;
  right: 1.5em;
  left: auto;

  display: inline-block;
  margin-top: 2px;
  width: 1.25em;
  height: 1.25em;
  background-position: center;
  background-size: 50%;
  background-repeat: no-repeat;
  color: white;
  cursor: pointer;
}

.DayPicker-NavButton:hover {
  opacity: 0.8;
}

.DayPicker-NavButton--prev {
  margin-right: 1.5em;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEgAAABgCAYAAAC+EjQcAAAACXBIWXMAABYlAAAWJQFJUiTwAAACWUlEQVR4Xu3dX07CQBDH8d1UA3dSX4xn4Rj8OQZn0eiDHqrRJjUSQKKlZZfZnZnfDK8tJHy6/bZLuiEGfx0Fttvt7WKx+Dwlie4TQtu2T7PZ7HnIwoFCCF3XvTdNc+9Ae4G+72Pbto/z+fxl6gwyOYLW6/XNcrl8jTE+ONCJwFhrzkGZGkFjrTEJlNIak0AprTEFlNMaU0A5rYEGomgNNBBFayCBKFsDCUTZGgigkq2BACrZGtVANVqjGqhGa1QBcbRGFRBHa1QAcbZGBRBna0QCSWqNSCBJrREFJLE1ooAktoYVSENrWIE0tIYFSFNrWIA0taYKkObWVAHS3JqiQAitKQqE0BpSIMTWkAIhtoYECLk1JEDIrckCstSaLCBLrUkCstiaJCCLrRkF8tac4wlh95Srt2YEqO/7/vxm3xIdaHwQONDESeJADnRdR30ETY2gw2V+tVp9XWeN+e7dfdDPjeJms2kc6f9BHlwO5Zf+X6hBoKHVv5gn0PS3Gl1Qt5+CvMUYBxe8Tn+8/j1GgXwSu5+sXnqcu677aJrm7tL9EfZLWrNq8Ye0JKDDiLDUpiwgS23KAvrbFuQ2kQAht4kECLlNpECIbSIFQmxTUSCENhUFQmhTFSDNbaoCpLlNLECa2sQCpKlNrEAa2sQKpKFNooAktkkUkMQ2iQSS1CaRQJLapAKIs00qgDjbpAqIo02qgDjapBqoRptUA9VoEwRQyTZBAJVsEyQQZZsggSjbBA1E0SZoIIo2mQLKaZMpoJw2mQRKaZNJoJQ2OVDwPz+6+GHboQfovwFbysDwfqHnswAAAABJRU5ErkJggg==');
}

.DayPicker-NavButton--next {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEgAAABgCAYAAAC+EjQcAAAACXBIWXMAABYlAAAWJQFJUiTwAAACTUlEQVR4Xu3cTW7CMBCG4VpKKza9UbvsWTgGcAkWOUuX7Wm4QFFrpapEJYMSEcse+5v5hhXiZ5FH4zdRSAjTNE0PyWMcx6ftdvudvsb8PNwC/WOcz+e3zWbzzozzt+2LQDHGj2EYXh3oZonNgRwOh8fdbhdDCFfLkQFvcYLSjT8ej8+n0+lrv9//MKCk27gKKP0CW5uygdjalA2UThNDm4qAGNpUBMTQpmpAVttUDchqm0SALLVJBMhSm8SBtLdJHEh7m5oCaWxTUyCNbeoGpKVN3YC0tAkCCLlNEEDIbYIDQmsTHBBam6CBENoEDYTQJjVAvdqkBqhXm1QCtWyTSqCWbVIPJN0m9UDSbTIFJNEmU0ASbTILVKtNZoFqtYkCqKRNFEAlbaIDym0THVBum6iB1rSJGmhNmxzoorTUJgeaua45vfbSgWaA0jY50J0r4x3IgcpunvAJ8gnyCSoT8AnK9/Pd/B0zP1CcAYoxfg7D8HL7lu/FLiJLNwpSA/npjozWLH2UboKWWuNAd1pDDbSmNdRAJTcfm21QbmvoJqjWHyCYmqCS1lBMUElrzALVao1ZoFqtMQUk0RpTQBKtMQEUQgj55wfLvqFmN9/r3/mggVq2RuUSa9kaNUDSxzW5RYJbYtLHNSqBEFoDvcQQWgMHhNYaOCC01kAAIbcGAgi5Nd2AtLSmG5CW1jQF0tiapkAaWyMOpL014kDaWyMCZKk1IkCWWlMNyGprqgFZbU0REENrioAYWpMNxNaabCC21qwG6vUDXe7J9Faf/wVKk1J2YFrIhAAAAABJRU5ErkJggg==');
}

.DayPicker-NavButton--interactionDisabled {
  display: none;
}

.DayPicker-Caption {
  display: table-caption;
  margin-bottom: 0.5em;
  padding: 0 0.5em;
  text-align: left;
}

.DayPicker-Caption > div {
  font-weight: 500;
  font-size: 1em;
}

.DayPicker-Weekdays {
  display: table-header-group;
  margin-top: 1em;
}

.DayPicker-WeekdaysRow {
  display: table-row;
}

.DayPicker-Weekday {
  display: table-cell;
  padding: 0.5em;
  color: #ccc;
  text-align: center;
  font-size: 0.75em;
}

.DayPicker-Weekday abbr[title] {
  border-bottom: none;
  text-decoration: none;
}

.DayPicker-Body {
  display: table-row-group;
}

.DayPicker-Week {
  display: table-row;
}

.DayPicker-Day {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
}

.DayPicker-Day > div {
  padding: 0.375em;
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 50%;
  position: relative;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.DayPicker-WeekNumber {
  display: table-cell;
  padding: 0.5em;
  min-width: 1em;
  border-right: 1px solid #eaecec;
  color: #8b9898;
  vertical-align: middle;
  text-align: right;
  font-size: 0.75em;
  cursor: pointer;
}

.DayPicker--interactionDisabled .DayPicker-Day {
  cursor: default;
}

.DayPicker-Footer {
  padding-top: 0.5em;
}

.DayPicker-TodayButton {
  border: none;
  background-color: transparent;
  background-image: none;
  box-shadow: none;
  color: #4a90e2;
  font-size: 0.875em;
  cursor: pointer;
}

/* Default modifiers */

.DayPicker-Day--today {
}

.DayPicker-Day--outside {
  color: #8b9898;
  cursor: default;
}

.DayPicker-Day--disabled {
  cursor: default;
  opacity: 0.15;
}

/* Example modifiers */

.DayPicker-Day--sunday {
  background-color: #f7f8f8;
}

.DayPicker-Day--sunday:not(.DayPicker-Day--today) {
  color: #dce0e0;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  position: relative;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside)
  > div {
  background-color: #ffcc33;
  color: #1c1c1c;
  font-weight: 700;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover
  > div {
  background-color: #707070;
}

.DayPicker:not(.DayPicker--interactionDisabled)
  .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover
  > div {
  background-color: #707070;
}

/* DayPickerInput */

.DayPickerInput {
  display: inline-block;
}

.DayPickerInput > input {
  padding: 0.375rem;
  caret-color: transparent;
}

.DayPickerInput-OverlayWrapper {
  position: relative;
}

.DayPickerInput-Overlay {
  position: absolute;
  top: 10px;
  right: 0;
  z-index: 1;
  
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}
